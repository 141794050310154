<template>
  <div>
    <div class="backClass"><a-button type="primary" @click="handleCancel"> 返回 </a-button></div>
    <a-form layout="inline">
      <a-form-item label="上传状态：">
        <a-select
          v-model="searchForm.succeed"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in statusList" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <div style="color: red">
      总计上传{{ dataNum.allCount }}条数据，上传成功{{ dataNum.successCount }}条，上传失败{{ dataNum.failedCount }}条
    </div>
    <div style="margin-top: 10px">
      <a-button type="primary" @click="afreshUpload" :disabled="disableType"> 批量重新上传 </a-button>
      <a-button type="primary" @click="batchDelete" style="margin-left: 15px" :disabled="disableType"> 批量删除 </a-button>
    </div>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :data-source="tableData"
      rowKey="id"
      :pagination="pagination.total ? pagination : false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="pageChange"
      bordered
    >
      <span slot="operation" slot-scope="text, record" class="action">
        <a-button type="link" :disabled="record.succeed !== 0||disableType" @click="reUpload(record)">重新上传</a-button>
        <a-button type="link" @click="deleteData(record)" :disabled="disableType">删除</a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "@/api/popup";
export default {
  data() {
    return {
      statusList: [
        { label: "全部", value: "" },
        { label: "成功", value: "1" },
        { label: "失败", value: "0" },
      ], //上传状态下拉值
      selectedRowKeys: [], //table选中数据
      disableType: false, //编辑状态
      searchForm: {
        //筛选项
        succeed: "",
      },
      dataNum: {
        allCount: 0,
        failedCount: 0,
        successCount: 0,
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "用户ID", dataIndex: "userId", key: "userId" },
        {
          title: "上传状态",
          dataIndex: "firstsite",
          key: "firstsite",
          customRender: function (text, record) {
            return (
              <div>
                <p>{record.succeed === 0 ? "失败" : "成功"}</p>
              </div>
            );
          },
        },
        { title: "失败原因", dataIndex: "cause", key: "cause" },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [], //table列表数据
    };
  },
  mounted() {
    this.getTableList();
    if (this.$route.query.type === "1") {
      this.disableType = false;
    } else {
      this.disableType = true;
    }
  },
  methods: {
    //获取table列表数据
    async getTableList() {
      let obj = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        succeed: this.searchForm.succeed,
        popupId: this.$route.query.id,
      };
      const res = await api.uploadexcellist(obj);
      if (res.code === "200") {
        this.tableData = res.data.popupList;
        this.pagination.total = res.data.total;
        this.dataNum.allCount = res.data.allCount;
        this.dataNum.failedCount = res.data.failedCount;
        this.dataNum.successCount = res.data.successCount;
        this.selectedRowKeys = [];
      }
    },
    //单条删除
    deleteData(record) {
      const _this = this;
      _this.$confirm({
        title: `提示`,
        content: "是否确认删除数据？删除后则此数据将对本弹窗失效",
        async onOk() {
          _this.selectedRowKeys.push(record.id);
          _this.upDeleteData();
        },
        onCancel() {},
      });
    },
    //批量删除
    batchDelete() {
      const _this = this;
      if (_this.selectedRowKeys.length !== 0) {
        _this.$confirm({
          title: `提示`,
          content: "是否确认删除数据？确认后该弹窗数据将失效",
          async onOk() {
            _this.upDeleteData();
          },
          onCancel() {},
        });
      } else {
        _this.$message.warning("请选择数据");
      }
    },
    // 提交删除数据
    async upDeleteData() {
      const _this = this;
      const res = await api.deletebatch({ ids: _this.selectedRowKeys });
      if (res.code === "200") {
        _this.$message.success("删除成功!");
        _this.getTableList();
      } else {
        _this.$message.error(res.msg);
      }
    },
    //单条重新提交
    reUpload(record) {
      const _this = this;
      _this.$confirm({
        title: `提示`,
        content: "确认是否重新提交？",
        async onOk() {
          _this.selectedRowKeys.push(record.id);
          _this.upAfreshUpload();
        },
        onCancel() {},
      });
    },
    //重新上传
    afreshUpload() {
      const _this = this;
      if (_this.selectedRowKeys.length !== 0) {
        _this.$confirm({
          title: `提示`,
          content: "确认是否重新提交？",
          async onOk() {
            _this.upAfreshUpload();
          },
          onCancel() {},
        });
      } else {
        _this.$message.warning("请选择数据");
      }
    },
    async upAfreshUpload() {
      const _this = this;
      const res = await api.uploadagain({ ids: _this.selectedRowKeys });
      if (res.code === "200") {
        _this.$message.success("提交成功!");
        _this.getTableList();
      } else {
        _this.$message.error(res.msg);
      }
    },
    //返回
    handleCancel() {
      this.$router.go(-1);
    },
    //筛选
    search() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.getTableList();
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
    // 批量选中
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //重置
    resetSearchForm() {
      this.searchForm = this.$options.data.call(this).searchForm;
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.getTableList();
    },
  },
};
</script>
<style scoped>
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
.up_file {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backClass {
  display: flex;
  justify-content: flex-end;
}
</style>